.App {
  margin: 0 auto;
  max-width: 800px;
  min-width: 800px;
}

.Content {
  margin: 24px;
}

.Header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.Header-Title {
  display: flex;
  align-items: center;
}

.Header-Title > h3 {
  margin: 0 10px;
}

.Sub-Header,
.Sub-Header-Title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Sub-Header-Title {
  margin-top: 24px;
}

.Sub-Header-Title > h5 {
  margin: 0 5px;
}

.Status-List {
  margin-top: 20px;
}

.Status-Block {
  background: #f9f9fb;
  border-radius: 8px;
  padding: 10px 0;
  padding-bottom: 2px;
  margin: 30px 0;
  box-shadow: 0px 4px 10px #cfcfcf;
  transition: box-shadow 0.1s ease-in-out;
  overflow: 'clip';
}

.Status-Block:hover {
  box-shadow: 0px 4px 20px #cfcfcf;
}

.Status-Block-Header {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.Status-Block-Header-Title {
  margin: 0 10px;
}

.Status-Block-Events {
  margin: 20px;
  display: flex;
  justify-content: space-around;
}

.Status-Block-Event-Day {
  text-align: center;
  width: 14%;
  border-radius: 4px;
}

.Status-Block-Event-Day > h3 {
  margin: 3px;
  font-size: small;
  color: #424242;
}

.Status-Block-Event-Day > h4 {
  height: 16px;
  margin: 3px;
  font-size: small;
  color: #424242;
}

.Status-Block-Event-Day > div {
  position: relative;
}

.Status-Block-Event-Day > div > img {
  width: 40%;
  display: inline-block;
  margin: 5px 0;
}

.Status-Block-Event-Day-Button {
  margin-top: 5px;
}

.Status-Event-Detail-List-Item > h4 {
  font-size: large;
  margin-top: 8px;
  margin-bottom: 4px;
}

.Status-Event-Detail-List-Item > h5 {
  font-size: small;
  color: #575757;
  margin-top: 2px;
  margin-bottom: 8px;
}

.Status-Event-Detail-List-Item > hr {
  margin: 6px 0;
}

.Status-Event-Detail-List-Item > p {
  font-size: smaller;
  margin-top: 0;
  margin-bottom: 32px;
}

.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}

.Footer > h5 {
  font-size: small;
  margin: 0;
  color: #b6b6b6;
  margin-right: 8px;
}

.Footer > a {
  margin: 0;
}
